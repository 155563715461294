@import '../../../styles/mixins/layout-mixins';
@import '../../../styles/mixins/font-mixins';

.container {
  position: relative;

  label {
    position: absolute;
    top: 13px;
    left: 16px;
    transition: all 0.2s ease;
    pointer-events: none;
    @include paragraph-M;
    color: $tp-phase2-medium-grey;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  .label_default {
    background-color: $tp-phase2-white;
  }

  input {
    width: 100%;
    height: 48px;
    padding: 16px;

    outline: transparent;
    border: 1px solid rgba($tp-phase2-black, 0.15);
    box-shadow: none !important;

    @include paragraph-M;
    color: $tp-phase2-black;
    font-size: 16px;
    font-weight: 500;

    &:focus {
      border-color: rgba($tp-phase2-black, 0.15);
    }

    &:focus,
    &:not(:placeholder-shown) {
      + label {
        top: -8px;
        padding-inline: 4px;
        font-size: 12px;
        line-height: 16px; /* 133.333% */
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  button {
    position: absolute;
    top: 14px;
    right: 12px;
    border: none;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  span {
    display: block;
    padding-top: 3px;
    padding-left: 16px;
    @include paragraph-M;
    color: $tp-phase2-text-helper;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }

  .message {
    visibility: hidden;

    &__visible {
      visibility: visible;
    }
  }

  &__error {
    label {
      color: $tp-phase2-system-red;
    }

    input {
      border-color: $tp-phase2-system-red;

      &:focus {
        border-color: $tp-phase2-system-red;
      }
    }

    .message {
      visibility: visible;
      color: $tp-phase2-system-red;
    }
  }
}

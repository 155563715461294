/* Import Manrope font from Google Fonts */
@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-VariableFont_wght.ttf');
  font-display: swap;
}

$font-serif-normal: Libertinus Serif Display;
$font-manrope-normal: 'Manrope';

// New TP Theme
$tp-white: #ffffff;
$tp-black: #000000;
$tp-light-black: #615f5f;
$tp-house-pink-50: #dca9bc;
$tp-house-pink-50-50a: #edd4dd;
$tp-dark-red: #5b0f0e;
$tp-grey: #75767d;
$tp-cool-grey: #c5c6cd;
$tp-cool-grey-75a: #d4d4da;
$tp-light-grey: #f8f8f8;
$tp-lighter-grey: #e5e5e5;
$tp-beige: #faf3f3;
$tp-light-background-gray: #e8e8e8;
$tp-border-grey: #e3e3e3;

// Rebuild TP Theme
$tp-house-pink: #c57372;
$tp-house-pink-2: #e1bab6;
$tp-house-pink-20: #e1bab614;
$tp-pink-bulletin-pink: #e1bab6;
$tp-rebuild-grey: #333333;
$tp-rebuild-mild-grey: #e8e8ed;
$tp-rebuild-medium-grey: #757575;
$tp-lightest-grey: #3333331a;
$tp-warm-grey: #625f5f;
$tp-rebuild-accent: #aec19a;
$tp-red: #9f1a18;
$tp-deep-red: #5b0f0e;
$tp-rebuild-light-grey-filled: #d5d4db;
$tp-rebuild-lighter-grey: #bdb8a7;
$tp-rebuild-lightest-grey: #5c5c5c;
$tp-rebuild-shadow-grey: #9f9f9f;
$tp-rebuild-grey-005: #f5f5f5;
$tp-rebuild-grey-01: #eaeaea;
$tp-rebuild-grey-02: #d6d6d6;
$tp-rebuild-grey-04: #adadad;
$tp-rebuild-grey-06: #858585;

$tp-phase2-brand-pink: #e1bab6;
$tp-phase2-black: #0b0b0b;
$tp-phase2-medium-grey: #757575;
$tp-phase2-light-grey: #e3e3e3;
$tp-phase2-very-light-grey: #f5f5f5;
$tp-phase2-white: #fff;
$tp-phase2-system-red: #f00;
$tp-phase2-text-helper: #5e6368;
$tp-phase2-light-pink: #d8aead;
$tp-phase2-deep-red: #5b0f0e;
$tp-gold: #956F23;

// Update the values and leave the variable names alone
$white: $tp-white !default;
$light: $tp-cool-grey !default;
$black: $tp-black !default;
$grey: $tp-cool-grey !default;
$mildgrey: $tp-warm-grey !default;
$mediumgrey: $tp-warm-grey !default;
$mediumgrey2: $tp-light-grey !default;
$darkgrey: $tp-warm-grey !default;
$pink: $tp-house-pink !default;
$red: $tp-red !default;

$neutral: #c7b9a9;
$green: #577d2a;

//responsive widths
$screen-phone-min: 320px;
$screen-tablet-min: 481px;
$screen-laptop-min: 769px;
$screen-desktop-min: 1025px;
$screen-desktop-lg-min: 1400px;

//Banner height
$alert-banner-height: 40px;
$alert-banner-height-mobile: 35px;
$alert-banner-height-two-line: 55px;
$alert-banner-height-two-line-mobile: 54px;

// Product Detail Page
$pdp-variant-out-of-stock-color: $tp-lighter-grey;
$pdp-image-slector-color: $tp-lighter-grey;

$content-max-width: 1876px;

//Font sizes
$tp-heading1-size: 96px;
$tp-heading2-size: 64px;
$tp-heading3-size: 48px;
$tp-heading4-size: 32px;
$tp-heading5-size: 24px;
$tp-heading6-size: 18px;

$tp-subHeading1-size: 31px;
$tp-subHeading2-size: 23px;
$tp-subHeading3-size: 17px;
$tp-subHeading4-size: 15px;
$tp-subHeading5-size: 14px;
$tp-subHeading6-size: 12px;

$tp-paragraph-XL-size: 23px;
$tp-paragraph-L-size: 17px;
$tp-paragraph-M-size: 15px;
$tp-paragraph-S-size: 13px;
$tp-paragraph-XS-size: 11px;
$tp-link-large-size: 15px;
$tp-link-small-size: 13px;

//Font line height
$tp-heading1-line-height: 85%;
$tp-heading2-line-height: 100%;
$tp-heading3-line-height: 100%;
$tp-heading4-line-height: 100%;
$tp-heading5-line-height: 100%;
$tp-heading6-line-height: 100%;

$tp-subHeading1-line-height: 42px;
$tp-subHeading2-line-height: 34px;
$tp-subHeading3-line-height: 28px;
$tp-subHeading4-line-height: 24px;
$tp-subHeading5-line-height: 21px;
$tp-subHeading6-line-height: 17px;

$tp-paragraph-XL-line-height: 34px;
$tp-paragraph-L-line-height: 28px;
$tp-paragraph-M-line-height: 24px;
$tp-paragraph-S-line-height: 21px;
$tp-paragraph-XS-line-height: 18px;
$tp-link-large-line-height: 24px;
$tp-link-small-line-height: 21px;

//Font tracking
$tp-heading1-tracking: -0.03em;
$tp-heading2-tracking: -0.025em;
$tp-heading3-tracking: -0.02em;
$tp-heading4-tracking: -0.015em;
$tp-heading5-tracking: -0.01em;
$tp-heading6-tracking: -0.005em;

$tp-subHeading1-tracking: 0.01em;
$tp-subHeading2-tracking: 0.015em;
$tp-subHeading3-tracking: 0.02em;
$tp-subHeading4-tracking: 0.02em;
$tp-subHeading5-tracking: 0.025em;
$tp-subHeading6-tracking: 0.025em;

$tp-paragraph-XL-tracking: 0.015em;
$tp-paragraph-L-tracking: 0.02em;
$tp-paragraph-M-tracking: 0.02em;
$tp-paragraph-S-tracking: 0.025em;
$tp-paragraph-XS-tracking: 0.03em;
$tp-link-large-tracking: 0.02em;
$tp-link-small-tracking: 0.025em;

.loadingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.svg {
  width: 100px;
  height: 100px;
}

.animateSpin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import '../config/variables';

@mixin font-style($font-size, $line-height, $tracking, $font-family) {
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $tracking;
  font-family: $font-family;
}

//Headings
@mixin heading1($custom_font: $font-serif-normal) {
  @include font-style($tp-heading1-size, $tp-heading1-line-height, $tp-heading1-tracking, $custom_font);
}
@mixin heading2($custom_font: $font-serif-normal) {
  @include font-style($tp-heading2-size, $tp-heading2-line-height, $tp-heading2-tracking, $custom_font);
}
@mixin heading3($custom_font: $font-serif-normal) {
  @include font-style($tp-heading3-size, $tp-heading3-line-height, $tp-heading3-tracking, $custom_font);
}
@mixin heading4($custom_font: $font-serif-normal) {
  @include font-style($tp-heading4-size, $tp-heading4-line-height, $tp-heading4-tracking, $custom_font);
}
@mixin heading5($custom_font: $font-serif-normal) {
  @include font-style($tp-heading5-size, $tp-heading5-line-height, $tp-heading5-tracking, $custom_font);
}
@mixin heading6($custom_font: $font-serif-normal) {
  @include font-style($tp-heading6-size, $tp-heading6-line-height, $tp-heading6-tracking, $custom_font);
}

@mixin subHeading1($custom_font: $font-manrope-normal) {
  @include font-style($tp-subHeading1-size, $tp-subHeading1-line-height, $tp-subHeading1-tracking, $custom_font);
}
@mixin subHeading2($custom_font: $font-manrope-normal) {
  @include font-style($tp-subHeading2-size, $tp-subHeading2-line-height, $tp-subHeading2-tracking, $custom_font);
}
@mixin subHeading3($custom_font: $font-manrope-normal) {
  @include font-style($tp-subHeading3-size, $tp-subHeading3-line-height, $tp-subHeading3-tracking, $custom_font);
}
@mixin subHeading4($custom_font: $font-manrope-normal) {
  @include font-style($tp-subHeading4-size, $tp-subHeading4-line-height, $tp-subHeading4-tracking, $custom_font);
}
@mixin subHeading5($custom_font: $font-manrope-normal) {
  @include font-style($tp-subHeading5-size, $tp-subHeading5-line-height, $tp-subHeading5-tracking, $custom_font);
}
@mixin subHeading6($custom_font: $font-manrope-normal) {
  @include font-style($tp-subHeading6-size, $tp-subHeading6-line-height, $tp-subHeading6-tracking, $custom_font);
}

@mixin paragraph-XL($custom_font: $font-manrope-normal) {
  @include font-style($tp-paragraph-XL-size, $tp-paragraph-XL-line-height, $tp-paragraph-XL-tracking, $custom_font);
}
@mixin paragraph-L($custom_font: $font-manrope-normal) {
  @include font-style($tp-paragraph-L-size, $tp-paragraph-L-line-height, $tp-paragraph-L-tracking, $custom_font);
}
@mixin paragraph-M($custom_font: $font-manrope-normal) {
  @include font-style($tp-paragraph-M-size, $tp-paragraph-M-line-height, $tp-paragraph-M-tracking, $custom_font);
}
@mixin paragraph-S($custom_font: $font-manrope-normal) {
  @include font-style($tp-paragraph-S-size, $tp-paragraph-S-line-height, $tp-paragraph-S-tracking, $custom_font);
}
@mixin paragraph-XS($custom_font: $font-manrope-normal) {
  @include font-style($tp-paragraph-XS-size, $tp-paragraph-XS-line-height, $tp-paragraph-XS-tracking, $custom_font);
}

//Links (replce with Manrope)
@mixin link-large($custom_font: $font-manrope-normal) {
  @include font-style($tp-link-large-size, $tp-link-large-line-height, $tp-link-large-tracking, $custom_font);
}
@mixin link-small($custom_font: $font-manrope-normal) {
  @include font-style($tp-link-small-size, $tp-link-small-line-height, $tp-link-small-tracking, $custom_font);
}
